export const comments = {
  ja: {
    score: {
      up: `生活習慣スコアは1月より%d点上がりました。寒さに負けず、良い調子です！<br>
      3月1日～15日は<b>Walk 個人チャレンジ 3rd</b> が開催されます。ニアピン賞は、Fitbitをはじめ豪華賞品が目白押し。<br>目標を決めて、日々の歩数を意識していきましょう。
      時間や距離など、どれくらい歩けば何歩になるのかを意識することがポイント！`,
      down: `生活習慣スコアは1月より%d点下がりました。分類別評価でどの項目に変化があったのか見てみましょう。<br>
      3月1日～15日は<b>Walk 個人チャレンジ 3rd</b> が開催されます。ニアピン賞は、Fitbitをはじめ豪華賞品が目白押し。<br>目標を決めて、日々の歩数を意識していきましょう。
      時間や距離など、どれくらい歩けば何歩になるのかを意識することがポイント！`,
      equal: `生活習慣スコアは1月と同じ点数です。分類別評価で、項目別に振り返ってみましょう。<br>
      3月1～15日は<b>Walk 個人チャレンジ 3rd</b> が開催されます。ニアピン賞は、Fitbitをはじめ豪華賞品が目白押し。<br>目標を決めて、日々の歩数を意識していきましょう。
      時間や距離など、どれくらい歩けば何歩になるのかを意識することがポイント！`,
      equal100: `生活習慣スコアは1月に引き続き<span class="good">満点</span>です。とても素晴らしいです！<br>
      3月1～15日は<b>Walk 個人チャレンジ 3rd</b> が開催されます。ニアピン賞は、Fitbitをはじめ豪華賞品が目白押し。<br>目標を決めて、日々の歩数を意識していきましょう。
      時間や距離など、どれくらい歩けば何歩になるのかを意識することがポイント！`,
    },
    steps: {
      up: `歩数スコアは1月より%d点上がりました。<br>
      ２つのことを意識するだけで、使われる筋肉が増え体力がつきやすくなります。<br>
      <b>①目線を前にする</b> <b>②肩を下げる</b>`,
      down: `歩数スコアは1月より%d点下がりました。<br>
      ２つのことを意識するだけで、使われる筋肉が増え体力がつきやすくなります。<br>
      <b>①目線を前にする</b> <b>②肩を下げる</b>`,
      equal: `歩数スコアは1月と同じです。<br>
      ２つのことを意識するだけで、使われる筋肉が増え体力がつきやすくなります。<br>
      <b>①目線を前にする</b> <b>②肩を下げる</b>`,
    },
    exercise: {
      up: `運動のスコアは1月より%d点上がりました。<br>
      活動的になる春に向けて、準備運動をしていきませんか？<br>
      <b><a href="https://go.andwell.jp/library/tag/9c34c316-5e2f-11ec-9728-06d158a16ffc">#ストレッチ</a></b>
      もしくは<b><a href="https://go.andwell.jp/library/tag/9c7267f5-5e2f-11ec-9ce8-0af363eb5062">#筋トレ</a></b>で検索！`,
      down: `運動のスコアは1月より%d点下がりました。<br>
      活動的になる春に向けて、準備運動をしていきませんか？<br>
      <b><a href="https://go.andwell.jp/library/tag/9c34c316-5e2f-11ec-9728-06d158a16ffc">#ストレッチ</a></b>
      もしくは<b><a href="https://go.andwell.jp/library/tag/9c7267f5-5e2f-11ec-9ce8-0af363eb5062">#筋トレ</a></b>で検索！`,
      equal: `運動のスコアは1月と同じです。<br>
      活動的になる春に向けて、準備運動をしていきませんか？<br>
      <b><a href="https://go.andwell.jp/library/tag/9c34c316-5e2f-11ec-9728-06d158a16ffc">#ストレッチ</a></b>
      もしくは<b><a href="https://go.andwell.jp/library/tag/9c7267f5-5e2f-11ec-9ce8-0af363eb5062">#筋トレ</a></b>で検索！`,
    },
    meal: {
      up: `食事のスコアは1月より%d点上がりました。<br>
      忙しい時期は、ついつい早食いになりやすいです。
      1食15分以上かけるようにすると満腹感が得やすくなります。`,
      down: `食事のスコアは1月より%d点下がりました。<br>
      忙しい時期は、ついつい早食いになりやすいです。
      1食15分以上かけるようにすると満腹感が得やすくなります。`,
      equal: `食事のスコアは1月と同じです。<br>
      忙しい時期は、ついつい早食いになりやすいです。
      1食15分以上かけるようにすると満腹感が得やすくなります。`,
    },
    drinking: {
      up: `飲酒のスコアは1月より%d点上がりました。<br>
      こちらの<b><a href="https://go.andwell.jp/library/5532f6df-5e3a-11ec-9ce8-0af363eb5062">健康コラム</a></b>を読み、お酒との付き合い方を1度みつめなおしてみませんか？`,
      down: `飲酒のスコアは1月より%d点下がりました。<br>
      こちらの<b><a href="https://go.andwell.jp/library/5532f6df-5e3a-11ec-9ce8-0af363eb5062">健康コラム</a></b>を読み、お酒との付き合い方を1度みつめなおしてみませんか？`,
      equal: `飲酒のスコアは1月と同じです。<br>
      こちらの<b><a href="https://go.andwell.jp/library/5532f6df-5e3a-11ec-9ce8-0af363eb5062">健康コラム</a></b>を読み、お酒との付き合い方を1度みつめなおしてみませんか？`,
    },
    sleep: {
      up: `睡眠のスコアは1月より%d点上がりました。<br>
      夕方から夜（就寝の3時間位前）に、ウォーキングなどの運動をして
      脳の温度を一時的に上げると快眠に繋がると言われています。`,
      down: `睡眠のスコアは1月より%d点下がりました。<br>
      夕方から夜（就寝の3時間位前）に、ウォーキングなどの運動をして
      脳の温度を一時的に上げると快眠に繋がると言われています。`,
      equal: `睡眠のスコアは1月と同じです。<br>
      夕方から夜（就寝の3時間位前）に、ウォーキングなどの運動をして
      脳の温度を一時的に上げると快眠に繋がると言われています。`,
    },
    stress: {
      up: `人はだれでも、新しい変化に対し体や心をいつもと同じような状態に維持しようとする傾向があります。
      就寝前などリラックスしたい時に、<b>「フーッ」と大きなため息</b>をついてみましょう。`,
      down: `人はだれでも、新しい変化に対し体や心をいつもと同じような状態に維持しようとする傾向があります。
      就寝前などリラックスしたい時に、<b>「フーッ」と大きなため息</b>をついてみましょう。`,
      equal: `人はだれでも、新しい変化に対し体や心をいつもと同じような状態に維持しようとする傾向があります。
      就寝前などリラックスしたい時に、<b>「フーッ」と大きなため息</b>をついてみましょう。`,
    },
  },
  en: {
    score: {
      up: `Your lifestyle score has gone up %d pts since January. You're doing great despite the cold!<br>
      The <b>3rd Walk Individual Challenge</b> is going to be held from March 1 to 15. The Best Effort Awards are packed with luxury prizes—including Fitbit. Set a goal, and monitor your steps every day.<br>
      The key is to know how much walking (time, distance, etc.) equates to how many steps!`,
      down: `Your lifestyle score has gone down %d pts since January. Use the categorized ratings to see what items have changed.<br>
      The <b>3rd Walk Individual Challenge</b> is going to be held from March 1 to 15. The Best Effort Awards are packed with luxury prizes—including Fitbit. Set a goal, and monitor your steps every day.<br>
      The key is to know how much walking (time, distance, etc.) equates to how many steps!`,
      equal: `Your lifestyle score is the same as January. Use the categorized ratings to review your progress item by item.<br>
      The <b>3rd Walk Individual Challenge</b> is going to be held from March 1 to 15. The Best Effort Awards are packed with luxury prizes—including Fitbit. Set a goal, and monitor your steps every day.<br>
      The key is to know how much walking (time, distance, etc.) equates to how many steps!`,
      equal100: `Your lifestyle score is the <span class="good">maximum</span> again, the same as January. Outstanding!<br>
      The <b>3rd Walk Individual Challenge</b> is going to be held from March 1 to 15. The Best Effort Awards are packed with luxury prizes—including Fitbit. Set a goal, and monitor your steps every day.<br>
      The key is to know how much walking (time, distance, etc.) equates to how many steps!`,
    },
    steps: {
      up: `Your steps score has gone up %d pts since January.<br>
      Just doing these two things will help you use more muscles and get stronger.<br>
      <b>(1) Look ahead.</b>   <b>(2) Keep your shoulders down.</b>`,
      down: `Your steps score has gone down %d pts since January. <br>
      Just doing these two things will help you use more muscles and get stronger.<br>
      <b>(1) Look ahead.</b>   <b>(2) Keep your shoulders down.</b>`,
      equal: `Your steps score is the same as January. <br>
      Just doing these two things will help you use more muscles and get stronger.<br>
      <b>(1) Look ahead.</b>   <b>(2) Keep your shoulders down.</b>`,
    },
    exercise: {
      up: `Your exercise score has gone up %d pts since January.<br>
      How about exercising to get ready for an active spring?<br>
      Search for <b><a href="https://go.andwell.jp/library/tag/9c34c316-5e2f-11ec-9728-06d158a16ffc">#stretching</a></b> or 
      <b><a href="https://go.andwell.jp/library/tag/9c7267f5-5e2f-11ec-9ce8-0af363eb5062">#Muscle training</a></b>!`,
      down: `Your exercise score has gone down %d pts since January.<br>
      How about exercising to get ready for an active spring?<br>
      Search for <b><a href="https://go.andwell.jp/library/tag/9c34c316-5e2f-11ec-9728-06d158a16ffc">#stretching</a></b> or 
      <b><a href="https://go.andwell.jp/library/tag/9c7267f5-5e2f-11ec-9ce8-0af363eb5062">#Muscle training</a></b>!`,
      equal: `Your exercise score is the same as January. <br>
      How about exercising to get ready for an active spring?<br>
      Search for <b><a href="https://go.andwell.jp/library/tag/9c34c316-5e2f-11ec-9728-06d158a16ffc">#stretching</a></b> or 
      <b><a href="https://go.andwell.jp/library/tag/9c7267f5-5e2f-11ec-9ce8-0af363eb5062">#Muscle training</a></b>!`,
    },
    meal: {
      up: `Your food score has gone up %d pts since January.<br>
      It's easy to eat too fast when you're busy.<br>
      Spending at least 15 minutes on a meal will help you feel full.`,
      down: `Your food score has gone down %d pts since January.<br>
      It's easy to eat too fast when you're busy.<br>
      Spending at least 15 minutes on a meal will help you feel full.`,
      equal: `Your food score is the same as January. <br>
      It's easy to eat too fast when you're busy.<br>
      Spending at least 15 minutes on a meal will help you feel full.`,
    },
    drinking: {
      up: `Your alcohol score has gone up %d pts since January.<br>
      How about reading this <b><a href="https://go.andwell.jp/library/5532f6df-5e3a-11ec-9ce8-0af363eb5062">Health Column</a></b>, then reviewing your alcohol habits?`,
      down: `Your alcohol score has gone down %d pts since January.<br>
      How about reading this <b><a href="https://go.andwell.jp/library/5532f6df-5e3a-11ec-9ce8-0af363eb5062">Health Column</a></b>, then reviewing your alcohol habits?`,
      equal: `Your alcohol score is the same as January.<br>
      How about reading this <b><a href="https://go.andwell.jp/library/5532f6df-5e3a-11ec-9ce8-0af363eb5062">Health Column</a></b>, then reviewing your alcohol habits?`,
    },
    sleep: {
      up: `Your sleep score has gone up %d pts since January.<br>
      Apparently, doing exercise like walking in the evening (about 3 hours before bed) will warm up your brain and help you get a good night's sleep.`,
      down: `Your sleep score has gone down %d pts since January.<br>
      Apparently, doing exercise like walking in the evening (about 3 hours before bed) will warm up your brain and help you get a good night's sleep.`,
      equal: `Your sleep score is the same as January.<br>
      Apparently, doing exercise like walking in the evening (about 3 hours before bed) will warm up your brain and help you get a good night's sleep.`,
    },
    stress: {
      up: `Everyone will basically try to keep their physical and mental condition the same as usual when faced with new changes. When you want to relax before bed, try a <b>big sigh</b>.`,
      down: `Everyone will basically try to keep their physical and mental condition the same as usual when faced with new changes. When you want to relax before bed, try a <b>big sigh</b>.`,
      equal: `Everyone will basically try to keep their physical and mental condition the same as usual when faced with new changes. When you want to relax before bed, try a <b>big sigh</b>.`,
    },
  },
}
